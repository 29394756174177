import React from "react";
import "../App.css";
import { Font, FontSize, TextColor } from "../utils/constants";
import Text from "./common/text";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

interface MainProps {}

const TITLE =
  "How much money the MTA has lost since Congestion Kathy betrayed us*";

const CONGESTION_PRICING_START_TS = "2024-06-30T00:00";
const TIMEZONE = "America/New_York";

const REVENUE_PER_YEAR = 1000 * 1000 * 1000;
const SECONDS_IN_YEAR = 365 * 24 * 60 * 60;
const REVENUE_PER_SECOND = REVENUE_PER_YEAR / SECONDS_IN_YEAR;

const CONTACT_EMAIL = "nyccongestionpricing@gmail.com";

export const Main: React.FC<MainProps> = () => {
  const JUNE_30_2024_12_00_AM_ET = dayjs
    .tz(CONGESTION_PRICING_START_TS, TIMEZONE)
    .unix();
  const now = dayjs().unix();
  const secondsSinceCongestionPricingStart = now - JUNE_30_2024_12_00_AM_ET;

  const [counter, setCounter] = React.useState(
    secondsSinceCongestionPricingStart
  );
  const [isCopied, setIsCopied] = React.useState(false);

  async function copyTextToClipboard(text: string) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }

  const handleCopyClick = () => {
    copyTextToClipboard(CONTACT_EMAIL)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    const interval = setInterval(() => {
      setCounter((counter) => counter + 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const revenueLost = Math.round(REVENUE_PER_SECOND * counter * 100) / 100;

  const subtitle =
    revenueLost < 0 ? (
      <span>
        <Text
          text={
            " (this number will start increasing once we are past 12:00 am on June 30th, as that is when congestion pricing was supposed to start)"
          }
          font={Font.ARVO_BOLD}
          fontSize={FontSize.BASE}
          color={TextColor.BLACK}
        />
      </span>
    ) : null;
  return (
    <div className="tw-px-8 tw-pt-24 tw-flex tw-flex-col sm:tw-pl-32">
      <div className="tw-w-144">
        <div className={"tw-pb-1"}>
          <Text
            text={TITLE}
            font={Font.ARVO_BOLD}
            fontSize={FontSize.XL3}
            color={TextColor.BLACK}
          />
        </div>
      </div>

      <div className="tw-w-144">
        <div className={"tw-pt-8"}>
          <Text
            text={`$${revenueLost < 0 ? "0" : revenueLost}`}
            font={Font.ARVO_BOLD}
            fontSize={FontSize.XL3}
            color={TextColor.BLACK}
          />
          {subtitle}
        </div>
      </div>

      <div className="tw-w-144">
        <div className={"tw-pt-8"}>
          <Text
            text={"*Assuming $1 billion / year"}
            font={Font.ARVO_BOLD}
            fontSize={FontSize.BASE}
            color={TextColor.BLACK}
          />
        </div>
      </div>
      <div className="tw-w-144">
        <div className={""}>
          <Text
            text={`Feedback? What do you want to see here? Are you a designer that wants to make this look better? Email ${" "}`}
            font={Font.ARVO_BOLD}
            fontSize={FontSize.BASE}
            color={TextColor.BLACK}
          />
          <span onClick={handleCopyClick} className="tw-cursor-pointer">
            <Text
              text={isCopied ? "Copied!" : CONTACT_EMAIL}
              font={Font.ARVO_BOLD}
              fontSize={FontSize.BASE}
              color={TextColor.BLACK}
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export default Main;
